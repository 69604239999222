<template>
  <div class="abouthz">
    <div>
      <img src="https://water-1302249616.cos.ap-nanjing.myqcloud.com//2021-12-07/02e7a9033aa146909c3bed00032f2e0f-banner_HZ云软.jpg" alt style="width:100%;">
    </div>
    <div v-for="item in data" :key="item.id" class>
      <div class="tit-i">
        <img src="../assets/Group.png" alt>
        <h3>{{ item.title }}</h3>
        <img src="../assets/Group Copy.png" alt>
        <h5>
          <span>{{ item.subTitle }}</span>
        </h5>
      </div>
      <!-- 我们是谁 -->
      <div v-if="item.type === 6" style="width:60vw;margin-left:20vw;">
        <div v-for="subitem in item.subitemEntities" :key="subitem.id" class="ql-editor" v-html="subitem.detail" />
      </div>
      <!-- 我们的团队 -->
      <div v-else-if="item.type === 7" style="width:60vw;margin-left:20vw;">
        <div v-for="subitem in item.subitemEntities" :key="subitem.id" class="ql-editor" v-html="subitem.detail" />
      </div>
    </div>
  </div>
</template>

<script>
import { aa } from '../api/pc/home'
export default {
  data() {
    return {
      data: []
    }
  },
  created() {
    aa(2)
      .then(res => {
        console.log('res', res)
        // res.data.item.parentDOS[1].subitemEntities.push(res.data.item.parentDOS[1].subitemEntities[0])
        this.data = res.data.item
      })
      .catch(e => {
        console.error(e)
      })
    // for (let i = 0; i < this.list.length; i++) {
    //   const item = this.list[i];
    //   console.log(item);
    // }
  }
}
</script>

<style  lang="scss" scoped>
.tit-i {
  padding: 20px 0;
  text-align: center;
  padding: 50px 0;
  line-height: 36px;
  overflow: hidden;
  h3 {
    font-size: 30px;
    color: #002;
    margin-bottom: 0px;
    display: inline-block;
  }
  h5 {
    font-size: 20px;
    color: #ffffff;
    text-transform: uppercase;
    font-family: Arial;
    margin-top: 10px;
    span {
      color: #e4392a;
    }
  }
  em {
    width: 45px;
    border-bottom: 1px solid #7f7f90;
    display: inline-block;
  }
}
.abouthz{
  .introduce {
  display: flex;
  margin: 0px auto;
  padding: 0px 114px;
  color: #7f7f90
}
.team {
  display: flex;
  margin: 0px auto;
  padding: 0px 114px;
  margin-bottom: 56px;
  color: #7f7f90
}
}

</style>
